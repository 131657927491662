<template>
  <div class="top-bar">
    <div style="max-width: 1500px; margin: auto; padding: 0 48px">
      <b-nav align="right" class="align-items-center">
        <b-nav-item to="/products" class="router-link-active-not-show">
          {{ $t("topBar_products") }}
        </b-nav-item>
        <b-nav-item to="/shops" class="router-link-active-not-show">
          {{ $t("topBar_shops") }}
        </b-nav-item>
        <b-nav-item to="/help-center" class="router-link-active-not-show">
          {{ $t("topBar_helpCenter") }}
        </b-nav-item>
        <b-nav-item to="/delivery-fee" class="router-link-active-not-show">
          {{ $t("topBar_deliveryFee") }}
        </b-nav-item>
        <lang-switcher type="nav"></lang-switcher>
      </b-nav>
    </div>
  </div>
</template>
<script>
import { BNav, BNavItem } from "bootstrap-vue";
import LangSwitcher from "../LangSwitcher.vue";

export default {
  components: {
    BNav,
    BNavItem,
    LangSwitcher,
  },
};
</script>
<style lang="scss" scoped>
.top-bar {
  font-size: 13px;
  background-color: #e0e7fa;
}

.nav {
  .nav-link {
    font-size: 14px;
    color: #737373;
    font-weight: lighter;
    text-transform: uppercase;
    padding: 4px 16px;
  }
  .nav-link .router-link-active {
    color: #737373;
    text-decoration: underline;
  }
}
</style>