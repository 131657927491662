import webPushService from './services/webPush.service';
import messaging from './firebase';
import { getToken } from 'firebase/messaging';
import authService from './services/auth.service';

const vapidKey =
  'BDaEkztySst0pRQCZBj1xxHZOZJLeKtPMj6UekClRQF-GHIwA4bI_AQWq9AMfc0M7IS8fDei9YsUu1kspDtVx7M';

export const getClientToken = async () => {
  try {
    const serviceWorkerRegistration =
      await navigator.serviceWorker.getRegistration(process.env.BASE_URL);

    if (!serviceWorkerRegistration) return;

    const token = await getToken(messaging, {
      vapidKey,
      serviceWorkerRegistration,
    });
    if (!token) return;

    if (
      authService.isAuthenticated() &&
      authService.getAuthUser()?.role?.roleName == 'BUYER'
    ) {
      await webPushService.saveToken({ token });
      console.log('Buyer token is generated and saved');
    }
    if (!authService.isAuthenticated()) {
      await webPushService.saveGuestToken({ token });
      console.log('Guest token is generated and saved');
    }
  } catch (err) {
    console.error('Error: while get sw or token', err);
  }
};

export const registerSW = () => {
  if ('Notification' in window && navigator.serviceWorker) {
    navigator.serviceWorker
      .register(`${process.env.BASE_URL}firebase-messaging-sw.js`)
      .then((reg) => {
        console.log('SW Registered: ', reg);

        getClientToken();
      })
      .catch((err) => {
        console.error('SW Registration has filed', err);
      });
  }
};

registerSW();
