import axios from '../helpers/axios-auth';

class WebPushService {
  saveToken(data) {
    return axios.post('/auth/token/subscribe', data);
  }

  saveGuestToken(data) {
    return axios.post('/guest/subscribe', data);
  }
}

export default new WebPushService();
