<template>
  <b-nav>
    <b-nav-item-dropdown right>
      <template #button-content>
        <b-icon-person-circle
          style="width: 23px; height: 23px"
        ></b-icon-person-circle>
      </template>

      <template v-if="loggedIn">
        <b-dropdown-item to="/user/profile" v-b-toggle.navbar-toggle-collapse>
          {{ $t("account_profile") }}
        </b-dropdown-item>
        <b-dropdown-item
          class="d-block d-lg-none"
          to="/user/favorites"
          v-b-toggle.navbar-toggle-collapse
        >
          {{ $t("common_favList") }}
        </b-dropdown-item>
        <b-dropdown-item
          to="/user/order-history"
          v-b-toggle.navbar-toggle-collapse
        >
          {{ $tc("common_order", 2) }}
        </b-dropdown-item>
        <b-dropdown-item to="/order-tracking" v-b-toggle.navbar-toggle-collapse>
          {{ $t("common_orderTracking") }}
        </b-dropdown-item>

        <b-dropdown-item>
          <p class="h5 m-0"><qr-dialog v-once></qr-dialog></p>
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item
          @click="$store.dispatch('auth/logout')"
          v-b-toggle.navbar-toggle-collapse
        >
          {{ $t("account_logout") }}
        </b-dropdown-item>
      </template>
      <template v-else>
        <b-dropdown-item to="/login" v-b-toggle.navbar-toggle-collapse>
          {{ $t("account_login") }}
        </b-dropdown-item>
        <b-dropdown-item to="/register" v-b-toggle.navbar-toggle-collapse>
          {{ $t("account_register") }}
        </b-dropdown-item>
      </template>
    </b-nav-item-dropdown>
  </b-nav>
</template>
<script>
import {
  BNav,
  VBToggle,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BIconPersonCircle,
} from "bootstrap-vue";
import QrDialog from "../QrDialog.vue";

export default {
  components: {
    BNav,
    QrDialog,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BIconPersonCircle,
  },
  data: () => ({}),
  directives: {
    "b-toggle": VBToggle,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
