<template>
  <div>
    <shop-header></shop-header>
    <router-view />
    <shop-footer></shop-footer>
  </div>
</template>
<script>
export default {
  components: {
    ShopHeader: () =>
      import(
        /* webpackChunkName: "top_header" */ "../components/header/shop/ShopHeader"
      ),
    ShopFooter: () =>
      import(
        /* webpackChunkName: "base_footer" */ "../components/footer/ShopFooter"
      ),
  },
  computed: {
    currentError() {
      return this.$store.getters.getCurrentError;
    },
  },
  beforeCreate() {
    if (this.$route.params.id) {
      this.$store.dispatch("getCurrentShop", this.$route.params.id);
    }
  },
  watch: {
    currentError: {
      handler(v) {
        if (v.error) {
          this.$notify({
            type: "error",
            duration: 8000,
            text: v.message,
          });
          this.$router.push("/").catch(() => {});
        }
      },
      deep: true,
    },
  },
};
</script>
