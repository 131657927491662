/**
 * @Module Auth
 * @Ref : https://vuex.vuejs.org/guide/modules.html
 *
 * @State : { status, user }
 * @Actions : { login, logout, register }
 * @Mutations : { loginSuccess, loginFailure, logout, registerSuccess, registerFailure }
 *
 */

import AuthService from '../services/auth.service';

const user = AuthService.getAuthUser();

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

const state = initialState;

const actions = {
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      AuthService.login(user)
        .then(res => {
          if (
            res.data.user &&
            res.data.user.role.roleName &&
            res.data.user.role.roleName != 'BUYER'
          ) {
            commit('loginFailure');
            return reject({
              response: { data: { error: true, message: "You can't login" } }
            });
          }

          AuthService.storeAuthentication(res.data.user, res.data.token);
          commit('loginSuccess', res.data.user);
          resolve(res.data.user);
        })
        .catch(error => {
          commit('loginFailure');
          reject(error);
        });
    });
  },
  logout({ commit }) {
    AuthService.logout();
    commit('logout');
  },
  register({ commit }, user) {
    return AuthService.register(user).then(
      response => {
        commit('registerSuccess');
        return Promise.resolve(response.data);
      },
      error => {
        commit('registerFailure');
        return Promise.reject(error);
      }
    );
  },
  updateUserInfo({ commit }, userInfo) {
    commit('updateUserInfo', userInfo);
  }
};

const mutations = {
  loginSuccess(state, user) {
    state.status.loggedIn = true;
    state.user = user;
  },
  loginFailure(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  logout(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  registerSuccess(state) {
    state.status.loggedIn = false;
  },
  registerFailure(state) {
    state.status.loggedIn = false;
  },
  updateUserInfo(state, userInfo) {
    state.user.registerDTO = userInfo;
    AuthService.updateAuthUserInfo(userInfo);
  }
};

export const auth = {
  namespaced: true,
  state,
  actions,
  mutations
};
