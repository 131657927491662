<template>
  <div>
    <span @click="openQRDialog">
      <svg
        id="Layer_1"
        enable-background="new 0 0 512.043 512.043"
        height="20"
        viewBox="0 0 512.043 512.043"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            fill="#2c3e50"
            d="m206.669 60.026h-146.65v146.651h146.651v-146.651zm-30 116.651h-86.65v-86.651h86.651v86.651z"
          />
          <path fill="#2c3e50" d="m118.344 118.351h30v30.002h-30z" />
          <path
            fill="#2c3e50"
            d="m60.019 452.017h146.651v-146.651h-146.651zm30-116.651h86.651v86.651h-86.651z"
          />
          <path
            fill="#2c3e50"
            d="m452.039 320.366h-30v101.651h-29.374v-22.317h-30v22.317h-36.621v30h125.995z"
          />
          <path fill="#2c3e50" d="m118.344 363.69h30v30.002h-30z" />
          <path
            fill="#2c3e50"
            d="m452.039 60.026h-146.651v146.651h146.651zm-30 116.651h-86.651v-86.651h86.651z"
          />
          <path fill="#2c3e50" d="m363.714 118.351h30v30.002h-30z" />
          <path fill="#2c3e50" d="m120.021 241.021h86.649v30h-86.649z" />
          <path fill="#2c3e50" d="m60.018 241.021h30.002v30h-30.002z" />
          <path fill="#2c3e50" d="m236.672 241.021h111.65v30h-111.65z" />
          <path
            fill="#2c3e50"
            d="m452.039 290.364v-49.343h-73.714v30h43.714v19.343z"
          />
          <path fill="#2c3e50" d="m241.04 120.029h30v86.649h-30z" />
          <path fill="#2c3e50" d="m241.04 60.025h30v30.002h-30z" />
          <path
            fill="#2c3e50"
            d="m271.04 392.013h22.317v-30h-22.317v-56.648h-30v146.652h55.002v-30h-25.002z"
          />
          <path
            fill="#2c3e50"
            d="m332.663 357.683h30v-22.317h24.343v-30h-78.686v30h24.343z"
          />
          <path fill="#2c3e50" d="m407.007.022v30h75.036v75.006h30v-105.006z" />
          <path fill="#2c3e50" d="m30 30.022h75.036v-30h-105.036v105.006h30z" />
          <path
            fill="#2c3e50"
            d="m482.043 482.021h-75.036v30h105.036v-105.006h-30z"
          />
          <path
            fill="#2c3e50"
            d="m30 407.015h-30v105.006h105.036v-30h-75.036z"
          />
        </g>
      </svg>
    </span>

    <b-modal id="qr-dialog-modal" size="md" centered scrollable hide-footer>
      <b-card>
        <div style="text-align: center">
          <vue-qrcode
            id="qr-code"
            :quality="1"
            :scale="10"
            :value="currentLink"
          />
          <b-button @click="downloadQR" variant="link">Download</b-button>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BModal, VBModal, BButton } from "bootstrap-vue";
import VueQrcode from "vue-qrcode";

export default {
  components: {
    BCard,
    BModal,
    VueQrcode,
    BButton,
  },
  data: () => ({
    currentLink: "",
  }),
  directives: {
    "b-modal": VBModal,
  },
  watch: {
    $route: {
      handler() {
        this.getWindowLocation();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openQRDialog() {
      this.$root.$emit("bv::show::modal", "qr-dialog-modal");
    },
    getWindowLocation() {
      this.currentLink = window.location.href;
    },
    downloadQR() {
      let el = document.getElementById("qr-code");
      const base64string = el.src;
      const pageImage = new Image();
      pageImage.src = base64string;
      let this_ = this;
      pageImage.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = pageImage.naturalWidth;
        canvas.height = pageImage.naturalHeight;
        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(pageImage, 0, 0);
        this_.saveScreenshot(canvas);
      };
    },
    saveScreenshot(canvas) {
      let fileName = "qrimage";
      const link = document.createElement("a");
      link.download = fileName + ".png";
      canvas.toBlob(function (blob) {
        link.href = URL.createObjectURL(blob);
        link.click();
      });
    },
  },
};
</script>
