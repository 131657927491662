<template>
  <form
    @submit.prevent="search()"
    class="search-box p-0 px-md-3 row no-gutters"
  >
    <b-input-group class="col-12 p-1 search-input-group">
      <b-form-input
        size="lg"
        type="search"
        :placeholder="placeholder"
        class="search-input"
        v-model.trim="searchKeyword"
        :disabled="isDisable"
        :clearable="false"
      ></b-form-input>
      <b-input-group-append>
        <b-button
          type="submit"
          class="search-box search-button"
          :disabled="isDisable"
        >
          <div class="d-flex align-items-center">
            <span class="pr-2 pl-md-2"><b-icon-search></b-icon-search></span>

            <span
              v-if="showLabel"
              class="px-3 d-none d-sm-none d-md-none d-lg-block"
            >
              {{ $t("search_labelForBtn") }}
            </span>
          </div>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </form>
</template>
<script>
import {
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BIconSearch,
} from "bootstrap-vue";

export default {
  props: {
    placeholder: {
      type: String,
    },
    type: {
      type: Boolean,
      default: false,
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    BIconSearch,
  },
  data: () => ({
    searchKeyword: "",
    searchTypeList: [
      { label: "common_product", value: "PRODUCT" },
      { label: "common_shopName", value: "SHOP" },
      // [KEY] lable's value is i18n's locale name
    ],
    searchType: "PRODUCT",
  }),
  methods: {
    search() {
      if (this.searchKeyword) {
        const _keyword = this.searchKeyword;
        this.searchKeyword = "";

        let searchList = "";

        if (this.searchType == "PRODUCT") {
          searchList = "HomeProducts";
          if (this.$route.path.includes("/shop/")) searchList = "ShopProducts"; // search in shop
        } else {
          searchList = "Shops";
        }

        this.$router
          .push({
            name: searchList,
            query: { name: _keyword },
          })
          .catch(() => {});
      }
    },
  },
};
</script>