import Home from '../views/HomeNext.vue'
import Login from "../views/Login.vue";
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ShopLayout from "../layouts/ShopLayout.vue";
import PageNotFound from "../views/404.vue";

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            layout: DefaultLayout
        }
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
            layout: DefaultLayout
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: DefaultLayout
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "BuyerRegister" */ '../views/Register.vue'),
        meta: {
            layout: DefaultLayout
        }
    },
    {
        path: '/shops',
        name: 'Shops',
        component: () => import(/* webpackChunkName: "Shops" */ '../views/Shops.vue'),
        meta: {
            layout: DefaultLayout
        }
    },
    {
        path: '/shop/:id',
        name: 'ShopHome',
        component: () => import(/* webpackChunkName: "ShopHome" */ '../views/shop/Home.vue'),
        meta: {
            layout: ShopLayout
        }
    },
    {
        path: '/shop/:id/products',
        name: 'ShopProducts',
        component: () => import(/* webpackChunkName: "ShopProducts" */ '../views/shop/Products.vue'),
        meta: {
            layout: ShopLayout
        }
    },
    {
        path: '/shop/:id/product/:productId',
        name: 'ShopProductDetail',
        component: () => import(/* webpackChunkName: "ShopProductDetail" */ '../views/shop/ProductDetailNext.vue'),
        meta: {
            layout: ShopLayout
        }
    },
    {
        path: '/cart',
        name: 'CartList',
        component: () => import(/* webpackChunkName: "CartList" */ '../views/CartList.vue'),
        meta: {
            layout: DefaultLayout,
            requiresAuth: true
        }
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: () => import(/* webpackChunkName: "Checkout" */ '../views/Checkout.vue'),
        meta: {
            layout: DefaultLayout,
            requiresAuth: true
        }
    },
    {
        path: '/user',
        redirect: '/user/profile'
    },
    {
        path: '/user/profile',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "UserProfile" */ '../views/buyer/Profile.vue'),
        meta: {
            layout: DefaultLayout,
            requiresAuth: true
        }
    },
    {
        path: '/user/order-history',
        name: 'UserOrderHistory',
        component: () => import(/* webpackChunkName: "UserOrderHistory" */ '../views/buyer/OrderHistory.vue'),
        meta: {
            layout: DefaultLayout,
            requiresAuth: true
        }
    },
    {
        path: '/user/favorites',
        name: 'UserFavorites',
        component: () => import(/* webpackChunkName: "UserFavorites" */ '../views/buyer/FavoriteList.vue'),
        meta: {
            layout: DefaultLayout,
            requiresAuth: true
        }
    },
    {
        path: '/order-tracking',
        name: 'OrderTracking',
        component: () => import(/* webpackChunkName: "OrderTracking" */ '../views/OrderTracking.vue'),
        meta: {
            layout: DefaultLayout,
            requiresAuth: true
        }
    },
    {
        path: '/products',
        name: 'HomeProducts',
        component: () => import(/* webpackChunkName: "HomeProducts" */ '../views/Products.vue'),
        meta: {
            layout: DefaultLayout
        }
    },
    {
        path: '/help-center',
        name: 'HelpCenter',
        component: () => import(/* webpackChunkName: "HelpCenter" */ '../views/HelpCenter.vue'),
        meta: {
            layout: DefaultLayout
        }
    },
    {
        path: '/delivery-fee',
        name: 'DeliveryFee',
        component: () => import(/* webpackChunkName: "DeliveryFee" */ '../views/DeliveryFeeList.vue'),
        meta: {
            layout: DefaultLayout,
            requiresAuth: true
        }
    }, 
    {
        path: '/:slug',
        name: 'PavilionDetail',
        component: () => import(/* webpackChunkName: "PavilionDetail" */ '../views/pavilion/PavilionDetail.vue'),
        meta: {
            layout: DefaultLayout
        }
    },
    {
      path: '/product-group/:id',
      name: 'ProductGroupDetail',
      component: () => import(/* webpackChunkName: "ShopHome" */ '../views/ProductGroupDetail.vue'),
      meta: {
          layout: DefaultLayout
      }
  },
]
