import axios from '../helpers/axios-auth';

class AdsService {
  getAds(searchParams = {}) {
    let params = {};

    if (searchParams.name) {
      params['name'] = searchParams.name;
    }

    if (searchParams.sitePage) {
      params['sitePage'] = searchParams.sitePage;
    }

    if (searchParams.placement) {
      params['placement'] = searchParams.placement;
    }

    return axios.get('/all-ads', { params });
  }
}

export default new AdsService();
