<template>
  <b-nav>
    <b-nav-item v-b-toggle.sidebar-menu>
      <p class="h5 d-inline">
        <b-icon-list></b-icon-list>
      </p>
    </b-nav-item>

    <b-sidebar id="sidebar-menu" shadow>
      <template #default="{ hide }">
        <div class="px-3 py-2">
          <b-nav vertical class="text-white text-left">
            <b-nav-item
              @click="hide"
              to="/products"
              class="router-link-active-not-show"
            >
              {{ $t("topBar_products") }}
            </b-nav-item>
            <b-nav-item
              @click="hide"
              to="/shops"
              class="router-link-active-not-show"
            >
              {{ $t("topBar_shops") }}
            </b-nav-item>
            <b-nav-item
              @click="hide"
              to="/help-center"
              class="router-link-active-not-show"
            >
              {{ $t("topBar_helpCenter") }}
            </b-nav-item>
            <b-nav-item
              @click="hide"
              to="/delivery-fee"
              class="router-link-active-not-show"
            >
              {{ $t("topBar_deliveryFee") }}
            </b-nav-item>
            <lang-switcher type="nav"></lang-switcher>
          </b-nav>

          <b-card no-body class="border-0 shadow-sm my-4 py-3">
            <div class="text-left px-3 pb-2 font-weight-bold">
              <strong>{{ $t("categoryCard_title") }}</strong>
            </div>

            <b-list-group>
              <b-list-group-item
                v-for="(shopCategory, index) in shopCategoryList"
                :key="`sc-${shopCategory.id}`"
                class="d-flex align-items-center border-0 px-3 py-2"
                :id="`home-category-card-item-${index}`"
                :to="{
                  path: '/shops?name=&shopCategoryId=' + shopCategory.id,
                }"
              >
                <b-img
                  :src="shopCategory.imgUrl || placeholder"
                  class="rounded-circle"
                  width="21"
                  height="21"
                >
                </b-img>

                <div class="ml-2 text-left text-truncate flex-grow-1">
                  {{ shopCategory.name }}
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
      </template>
    </b-sidebar>
  </b-nav>
</template>

<script>
import {
  VBToggle,
  BNav,
  BNavItem,
  BIconList,
  BSidebar,
  BCard,
  BImg,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import shopService from "../../services/shop.service";
import LangSwitcher from "../LangSwitcher.vue";

export default {
  components: {
    BIconList,
    BNav,
    BNavItem,
    BSidebar,
    BCard,
    BImg,
    BListGroup,
    BListGroupItem,
    LangSwitcher,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data: () => ({
    text: "",
    shopCategoryList: [],
  }),
  mounted() {
    this.getShopCategory();
  },
  methods: {
    async getShopCategory() {
      try {
        let res = await shopService.getShopCategoy("ACTIVE");
        let _shc = res.data;

        this.shopCategoryList = _shc.map((sc) => {
          return {
            ...sc,
            imgUrl: sc.imageUrl ? sc.imageUrl.replace("\\", "/") : "",
          };
        });
      } catch (error) {
        console.error(error);
        this.$nprogress.done();
      }
    },
  },
};
</script>