const getRVIs = () => {
  let rVIs_ = localStorage.getItem('RVIs')
  if (rVIs_) return JSON.parse(rVIs_)
  else return null
}

let list = getRVIs()

const initialState = list && list.length > 0 ? { list } : { list: [] }

const state = initialState

const getters = {
  getRVItemList: (state) => state.list
}

const actions = {
  save({ commit, state }, payload) {
    commit('getItemFromLocalStorage')
    commit('saveIntoRV', payload)
    // limit 6 item
    if (state.list.length > 6) commit('limitFirst6Items', 6)
    // set back to localStorage
    localStorage.setItem('RVIs', JSON.stringify(state.list))
  }
}

const mutations = {
  saveIntoRV(state, payload) {
    let index = -1
    if (state.list.length > 0)
      index = state.list.findIndex((i) => i.id == payload.id)

    if (index >= 0) state.list.unshift(state.list.splice(index, 1)[0])
    else state.list.unshift(payload)
  },
  limitFirst6Items(state, endIndex) {
    state.list = state.list.slice(0, endIndex)
  },
  getItemFromLocalStorage(state) {
    let list = getRVIs()
    if (list && list.length >= 0) state.list = list
  }
}

export const recentlyViewedItems = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
