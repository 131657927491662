<template>
  <div>
    <router-link :to="link">
      <b-img-lazy
        v-if="type == 'logo'"
        class="site-logo"
        :src="logo"
        fluid
        v-bind="{ blank: true }"
        :alt="alt"
      ></b-img-lazy>
      <b-img-lazy
        v-else
        class="shop-logo"
        :height="height"
        :src="logo"
        fluid
        v-bind="{ blank: true }"
        :alt="alt"
      ></b-img-lazy>
    </router-link>
  </div>
</template>
<script>
import { BImgLazy } from "bootstrap-vue";

export default {
  props: {
    logo: {
      type: String,
    },
    alt: {
      type: String,
      default: "YomaShops",
    },
    height: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "logo",
    },
    link: {
      type: String,
      default: "/",
    },
  },
  data: () => ({}),
  components: {
    BImgLazy,
  },
};
</script>
