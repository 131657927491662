import axios from "../helpers/axios-auth"

class VisitorCountService {


    postPagehit(buyer) {
        return axios.post("/auth/page-hit", buyer);
    }

    
}

export default new VisitorCountService()
