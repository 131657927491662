<template>
  <div>
    <b-carousel
    :interval="7000"
    controls
    indicators
    background="#ababab"
    style="text-shadow: 1px 1px 2px #333"
    img-width="1280"
    img-height="512"
    id="home-carousel-banner"
  >
    <template v-if="carouselList && carouselList.objArr">
      <b-carousel-slide
        v-for="carousel in carouselList.objArr"
        :key="carousel.url"
        class="home-carousel"
      >
        <template #img v-if="carousel.redirectLink">
          <a
            :href="carousel.redirectLink"
            :target="carousel.newTab ? '_blank' : '_self'"
          >
            <b-img-lazy
              class="home-carousel-item"
              :src="carousel.url || placeholder"
              alt="image slot"
            >
            </b-img-lazy>
          </a>
        </template>
        <template #img v-else>
          <b-img-lazy
            class="home-carousel-item"
            :src="carousel.url || placeholder"
            alt="image slot"
          >
          </b-img-lazy>
        </template>
      </b-carousel-slide>
    </template>
    <template v-else>
      <b-carousel-slide class="home-carousel">
        <b-skeleton-img class="home-carousel-item"></b-skeleton-img>
      </b-carousel-slide>
      
    </template>
  </b-carousel>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide, BImgLazy,BSkeletonImg } from "bootstrap-vue";

export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BImgLazy,
    BSkeletonImg
  },
  computed: {
    carouselList() {
      return this.$store.getters.getSetting("CAROUSEL");
    },
  },
};
</script>
