import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import store from '../store';
import AuthService from '../services/auth.service';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (
      to.name == 'ProductGroupDetail' &&
      from.name == 'ProductGroupDetail'
    ) {
      return;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // exit from shop
  if (!to.fullPath.includes('/shop/')) store.dispatch('removeCurrentShop');

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!AuthService.isAuthenticated()) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
