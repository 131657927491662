import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

var firebaseConfig = {
  apiKey: 'AIzaSyAon3x_SAo_CgJhPk4ESWVsMBr_ofwWtIw',
  authDomain: 'buyer-shop-android.firebaseapp.com',
  projectId: 'buyer-shop-android',
  storageBucket: 'buyer-shop-android.appspot.com',
  messagingSenderId: '838296455488',
  appId: '1:838296455488:web:af4a755636b8e12eb552a5'
};

let firebase = initializeApp(firebaseConfig);

export default getMessaging(firebase);
