import axios from '../helpers/axios-auth';
import messaging from '../firebase';
import { deleteToken } from 'firebase/messaging';

const _PREFIX_ = 'ecp_';

export const USER_KEY = _PREFIX_ + 'user';
export const TOKEN_KEY = _PREFIX_ + 'token';

class AuthService {
  login(user) {
    return axios.post('/login', user);
  }

  async logout() {
    try {
      if (Notification.permission == 'granted') {
        await deleteToken(messaging);
      }
      localStorage.removeItem(USER_KEY);
      localStorage.removeItem(TOKEN_KEY);
      location.href = process.env.VUE_APP_PUBLIC_PATH;
    } catch (err) {
      console.error(err);
    }
  }

  register(user) {
    return axios.post('/user', user);
  }

  changePassword(user) {
    let passwd = {
      oldPassword: user.oldPassword,
      newPassword: user.newPassword
    };

    return axios.put('/auth/change-password-by-current-user', passwd);
  }

  storeAuthentication(user, token) {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  getAuthentication() {
    // return authorization header with jwt token
    let accessToken = localStorage.getItem(TOKEN_KEY);

    if (accessToken) return 'Bearer ' + accessToken;
    else return null;
  }

  isAuthenticated() {
    if (localStorage.getItem(TOKEN_KEY) && localStorage.getItem(USER_KEY))
      return true;
    else return false;
  }

  getAuthUser() {
    if (this.isAuthenticated()) {
      let user = localStorage.getItem(USER_KEY);

      if (user) return JSON.parse(user);
      else return null;
    } else return null;
  }

  updateAuthUserInfo(userData) {
    if (this.isAuthenticated()) {
      const user = JSON.parse(localStorage.getItem(USER_KEY));

      user.registerDTO = userData;

      localStorage.setItem(USER_KEY, JSON.stringify(user));
    }
  }

  sendToken(data) {
    return axios.post('/notification/token', data);
  }

  saveToken(data) {
    return axios.post('/auth/token/subscribe', data);
  }
}

export default new AuthService();
