import axios from "../helpers/axios-auth"

class cartService {

  registerToCart(itemList = []) {
    return axios.post("/auth/temp-checkout", itemList);
  }

  getCartList() {
    return axios.get("/auth/temp-checkout/")
  }

  checkout() {
    return axios.get("/auth/checkout")
  }

  removeFromCart(idList = []) {
    return axios.post("/auth/remove-temp-checkout", idList)
  }

}
export default new cartService();
