<template>
  <div style="max-width: 1500px; margin: auto">
    <header class="header-container">
      <b-container fluid class="p-0">
        <div class="d-flex align-items-center topheader-row-1">
          <div class="flex-shrink-1">
            <!-- logo -->
            <div class="logo-holder">
              <logo :logo="(mainLogo && mainLogo.value) || ''"></logo>
            </div>
          </div>
          <div class="flex-grow-1">
            <!-- search -->
            <search
              class="search"
              :placeholder="$t('search_placeholder')"
            ></search>
          </div>
          <div class="flex-shrink-1">
            <!-- user menu  -->
            <top-menu></top-menu>
          </div>
        </div>
      </b-container>
    </header>
  </div>
</template>

<script>
import Logo from "./Logo";
import Search from "./Search";
import TopMenu from "./TopMenu_Next.vue";

export default {
  components: {
    Logo,
    Search,
    TopMenu,
  },
  data: () => ({
    text: "",
  }),
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
    mainLogo() {
      return this.$store.getters.getSetting("LOGO");
    },
  },
};
</script>