import axios from '../helpers/axios-auth';

let functionHolder = {};

functionHolder.getListByPagination = (searchParams, page, size, sortedBy) => {
  if (!page) page = 0;
  else page -= 1;

  if (!size) size = 50;

  let params = {
    page,
    size,
    sort: sortedBy,
  };

  if (searchParams.pavilionTypeId) {
    params['pavilionTypeId'] = searchParams.pavilionTypeId;
  }
  if (searchParams.organizationId) {
    params['organizationId'] = searchParams.organizationId;
  }
  if (searchParams.userId) {
    params['userId'] = searchParams.userId;
  }
  if (searchParams.name) {
    params['name'] = searchParams.name;
  }
  if (searchParams.slugName) {
    params['slugName'] = searchParams.slugName;
  }
  if (searchParams.status) {
    params['status'] = searchParams.status;
  }

  // for public
  params['viewOnlyPublish'] = false;

  return axios.get('/all-pavilion', { params });
};

functionHolder.save = (data) => {
  if (data.id) return axios.put('/auth/pavilion', data);
  else return axios.post('/auth/pavilion', data);
};

functionHolder.getById = (id) => {
  return axios.get('/pavilion/' + id, { params: { viewOnlyPublish: true } });
};

functionHolder.getAll = (searchParams) => {
  let params = {};

  if (searchParams.pavilionTypeId) {
    params['pavilionTypeId'] = searchParams.pavilionTypeId;
  }
  if (searchParams.organizationId) {
    params['organizationId'] = searchParams.organizationId;
  }
  if (searchParams.userId) {
    params['userId'] = searchParams.userId;
  }
  if (searchParams.name) {
    params['name'] = searchParams.name;
  }
  if (searchParams.slugName) {
    params['slugName'] = searchParams.slugName;
  }
  if (searchParams.status) {
    params['status'] = searchParams.status;
  }

  // for public
  params['viewOnlyPublish'] = true;

  return axios.get('/pavilions', { params });
};
functionHolder.getPavilions = () => {
  return axios.get('/pavilions', { params: { onlyHeader: true, status: "ACTIVE" } });
};

export const pavilionService = functionHolder;
