<template>
  <b-container fluid class="p-0 bg-light">
    <div style="max-width: 1500px; margin: auto">
      <section class="home-banner">
        <!-- above carousel ads -->
        <div v-if="aboveCarouselAds" class="mt-1 mb-4">
          <div v-if="aboveCarouselAds.redirectLink">
            <a :href="aboveCarouselAds.redirectLink" target="_blank"
              ><b-img :src="aboveCarouselAds.imageUrl" class="home-ads"></b-img
            ></a>
          </div>
          <b-img
            v-else
            :src="aboveCarouselAds.imageUrl"
            class="home-ads"
          ></b-img>
        </div>

        <b-row no-gutters class="d-flex row-flex">
          <b-col cols="12" sm="3" md="3" lg="3" class="d-none d-md-block">
            <category-card class="mr-0 mr-sm-3"></category-card>
          </b-col>
          <b-col cols="12" sm="9" md="9" lg="9">
            <carousel class="mt-3 mt-sm-0"></carousel>
          </b-col>
        </b-row>

        <!-- below carousel ads -->
        <div v-if="belowCarouselAds" class="mt-5 mb-1">
          <div v-if="belowCarouselAds.redirectLink">
            <a :href="belowCarouselAds.redirectLink" target="_blank"
              ><b-img :src="belowCarouselAds.imageUrl" class="home-ads"></b-img
            ></a>
          </div>
          <b-img v-else :src="belowCarouselAds.imageUrl" class="home-ads"></b-img>
        </div>
      </section>

      <section class="spAndpromo">
        <product-group type="['SPECIAL_OFFER', 'PROMOTION']" layout="half" />

        <!-- <b-row no-gutters>
          <b-col cols="12" sm="6" md="6" lg="6">
            <special-offer class="mr-0 mr-sm-1"></special-offer>
          </b-col>
          <b-col cols="12" sm="6" md="6" lg="6">
            <promotion class="ml-0 ml-sm-1 mt-3 mt-sm-0"></promotion>
          </b-col>
        </b-row> -->
      </section>

      <section class="recommended-shop">
        <!-- above recommended shop ads -->
        <div v-if="aboveRecommendedShopAds" class="mt-1 mb-5">
          <div v-if="aboveRecommendedShopAds.redirectLink">
            <a :href="aboveRecommendedShopAds.redirectLink" target="_blank"
              ><b-img :src="aboveRecommendedShopAds.imageUrl" class="home-ads"></b-img
            ></a>
          </div>
          <b-img
          v-else
            :src="aboveRecommendedShopAds.imageUrl"
            class="home-ads"
          ></b-img>
        </div>

        <b-row no-gutters>
          <b-col cols="12">
            <recommended-shop class="mt-3 mt-sm-0"></recommended-shop>
          </b-col>
        </b-row>

        <!-- below recommended shop ads -->
        <div v-if="belowRecommendedShopAds" class="mb-1 mt-5">
          <div v-if="belowRecommendedShopAds.redirectLink">
            <a :href="belowRecommendedShopAds.redirectLink" target="_blank"
              ><b-img :src="belowRecommendedShopAds.imageUrl" class="home-ads"></b-img
            ></a>
          </div>
          <b-img
          v-else
            :src="belowRecommendedShopAds.imageUrl"
            class="home-ads"
          ></b-img>
        </div>
      </section>

      <section>
        <product-group
          type="['PRODUCT_COLLECTION']"
          class="mt-4 mt-sm-0"
          layout="full"
        ></product-group>
      </section>

      <section>
        <pavilion />
      </section>

      <recent-view class="mt-3 mt-sm-0"></recent-view>
    </div>
  </b-container>
</template>
<script>
import { VBToggle, BImg } from "bootstrap-vue";
import visitorCountService from "../services/visitor-count.service";
import CategoryCard from "../components/home/CategoryCard.vue";
import Carousel from "../components/home/Carousel.vue";
import Pavilion from "../components/home/Pavilion.vue";
// import ProductGroupSkeleton from "../components/skeletons/ProductGroupSkeleton.vue"
export default {
  components: {
    CategoryCard,
    Carousel,
    Pavilion,
    // ProductGroupSkeleton,
    // SpecialOffer: () => import("../components/home/SpecialOffer.vue"),
    // Promotion: () => import("../components/home/Promotion.vue"),
    RecommendedShop: () => import("../components/home/RecommendedShop.vue"),
    ProductGroup: () => import("../components/home/ProductGroup.vue"),
    RecentView: () => import("../components/home/RecentView.vue"),
    BImg,
  },
  data: () => ({
    placeholder: require("../assets/images/yoma-placeholder.png"),
    filters: {
      registerId: "",
      name: "",
      code: "",
      status: "ACTIVE",
      unitId: "",
      brandId: "",
      categoryId: "",
      isSpecialOffer: "",
      isPromo: "",
      page: 1,
      size: 30,
      sort: "",
    },
    products: [],
    totalElements: 0,
    perPage: 5,
    currentPage: 1,
    productGroupList: [],
  }),
  directives: {
    "b-toggle": VBToggle,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
    homeAds() {
      return this.$store.getters.getSetting("HOMEPAGEADS");
    },
    aboveCarouselAds() {
      return this.$store.getters.getAds("ABOVE", "CAROUSEL", "HOME");
    },
    belowCarouselAds() {
      return this.$store.getters.getAds("BELOW", "CAROUSEL", "HOME");
    },
    aboveRecommendedShopAds() {
      return this.$store.getters.getAds("ABOVE", "RECOMM_SHOP", "HOME");
    },
    belowRecommendedShopAds() {
      return this.$store.getters.getAds("BELOW", "RECOMM_SHOP", "HOME");
    },
  },
  mounted() {
    // page count
    if (this.loggedIn) {
      this.countPublicHit();
    }
  },
  metaInfo() {
    return {
      title: "ပင်မစာမျက်နှာ",
    };
  },

  methods: {
    async countPublicHit() {
      try {
        const obj = {
          homepageHit: true,
        };
        await visitorCountService.postPagehit(obj);
      } catch (error) {
        console.error(error);
      }
    },
    viewProducts(categoryId) {
      this.$router
        .push({
          name: "HomeProducts",
          query: { categoryId },
        })
        .catch(() => {});
    },
    enterShop(shopId) {
      this.$router.push({ name: "ShopHome", params: { id: shopId } });
    },
    goToShops(category) {
      this.$router
        .push({
          name: "Shops",
          query: { shopCategoryId: category.id },
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.home-ads {
  width: 100%;
  border-radius: 16px;
}
</style>
