<template>
  <div v-if="pavilions.length>0">
    <h5 class="font-weight-bold text-uppercase text-left">
      <b-icon-shop class="text-primary"/>
      Pavilions</h5>
    <b-card  class="custom-card border-0 shadow-sm">
      <b-row no-gutters>
      <b-col
      cols="12"
        :md="pavilions.length==1?'12':'6'"
        class="p-2"
       v-for="p in pavilions" :key="p.id">
        <router-link :to="`/${p.slugName}`">
          <b-img
        :src="p.largeImageUrl" fluid-grow height="100"></b-img>
        </router-link>
      </b-col>
    </b-row>
    </b-card>
  </div>
</template>

<script>
import { pavilionService } from "../../services/pavilion.service";
import { BImg, BIconShop,BCard } from "bootstrap-vue";
export default {
  components:{
    BImg,
    BIconShop,
    BCard
  },
  data() {
    return {
      pavilions: [],
    };
  },
  created() {
    this.getAllPavilion();
  },
  methods: {
    async getAllPavilion() {
      try {
        const res = await pavilionService.getPavilions();
        this.pavilions = await res.data;
        console.log(this.pavilions.length);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>
