import axios from 'axios';
import AuthService from '../services/auth.service';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

// defalut request header
instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use((req) => {
  if (AuthService.isAuthenticated())
    req.headers['Authorization'] = AuthService.getAuthentication();
  else delete req.headers['Authorization']; // remove Authorization from header

  return req;
});

// generate public token
const secretKey = Buffer.from(process.env.VUE_APP_KEY, 'base64');
var jsonwebtoken = require('jsonwebtoken');
// set expire time to 1 hour
var token = jsonwebtoken.sign(
  {
    sub: process.env.VUE_APP_PUBLIC_KEY,
    exp: Math.floor(Date.now() / 1000) + 60 * 60,
  },
  secretKey,
  { algorithm: 'HS512' }
);
instance.defaults.headers.common['Auth'] = token;
//
instance.interceptors.response.use(
  (res) => {
    if (res.config.url.includes('/login')) {
      // if login api store authentication
    }
    return res;
  },
  (error) => {
    if (error.response && error.response.status == '400') {
      AuthService.logout();
    } else if (error.response && error.response.data.fieldErrorMessages) {
      let obj = error.response.data.fieldErrorMessages;
      let message = obj[Object.keys(obj)[0]];
      error.response.data.message = message;
      throw error;
    } else {
      throw error;
    }
  }
);

export default instance;
