import axios from "../helpers/axios-auth"

class ShopService {

    getShops(companyName) {
        return axios.get("/register-by-seller-type", { params: { companyName } })
    }

    getShopsPaginate(filterParams, page, size) {

        if (!page) page = 0;
        else page -= 1;

        if (!size) size = 24;

        let params = { page, size };

        if (filterParams.shopCategoryId)
            params["shopCategoryId"] = filterParams.shopCategoryId;

        if (filterParams.name)
            params["companyName"] = filterParams.name;

        return axios.get("/register-by-seller-type", { params })
    }

    getShopById(id) {
        return axios.get("/seller/" + id)
    }

    getShopBySlug(slug) {
        return axios.get("/shop-by-slug/" + slug)
    }

    getShopCategoy(status) {
        let params = { status }
        return axios.get("/shop-categories", { params })
    }

    getRecommendedShops(params) {
        return axios.get("/recommended-shops", { params })
    }

    getAllShopByPavilion(filterParams, page, size) {
        if (!page) page = 0
        else page -= 1
    
        if (!size) size = 50
    
        let params = { page, size }
    
        if (filterParams.slugName) params['slugName'] = filterParams.slugName
        
        return axios.get('/all-seller-by-pavilion', { params })
    }
}
export default new ShopService()
