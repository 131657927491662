<template>
  <div id="app">
    <nprogress-container></nprogress-container>
    <component :is="$route.meta.layout || 'div'"> </component>
    <notifications position="top center" class="g-notification" />
  </div>
</template>
<script>
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import settingService from "./services/system-setting.service";
import adsService from "./services/ads.service";
import { getClientToken } from "./registerServiceWorker";

export default {
  components: {
    NprogressContainer,
  },
  data: () => ({
    metaTitle: "",
    userToken: "",
  }),
  computed: {
    favicon() {
      return this.$store.getters.getSetting("FAVICON");
    },
    siteInfo() {
      return this.$store.getters.getSetting("SITEINFO");
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  watch: {
    favicon(v) {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/png";
      link.rel = "shortcut icon";
      link.href = v.value || "";
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    siteInfo: {
      handler(v) {
        let tmp = JSON.parse(v.value);
        this.metaTitle = tmp.metaTitle;
      },
    },
    loggedIn: {
      handler() {
        getClientToken();
      },
    },
  },
  async created() {
    try {
      let res = await settingService.getSystemSetting();
      this.$store.dispatch("saveSettings", res.data.systemSettingList);
      // get temp cartList
      if (this.loggedIn) this.$store.dispatch("getCartList");
      // get ads
      res = await adsService.getAds();
      this.$store.dispatch("saveAds", res.data.adsList);
    } catch (error) {
      console.error(error);
    }
  },
  metaInfo() {
    return {
      title:
        "The best platform of Myanmar, to choose and buy for your suitable product for your daily life.",
      titleTemplate: `%s | ${this.metaTitle}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "နေ့စဉ်ဘဝအတွက် လိုအပ်သော ကုန်ပစ္စည်းများကိုရွေးချယ်ဝယ်ယူနိုင်မည့် မြန်မာ့အကောင်းဆုံး အွန်လိုင်းဈေးကွက်တစ်ခု",
        },
      ],
    };
  },
};
</script>
