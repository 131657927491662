<template>
  <header>
    <b-container fluid class="px-2 pt-2">
      <div>
        <b-row no-gutters class="align-items-center">
          <b-col cols="3">
            <!-- top bar menu  -->
            <mobile-side-nav></mobile-side-nav>
          </b-col>
          <b-col cols="6">
            <!-- logo -->
            <div class="logo-holder">
              <logo :logo="(mainLogo && mainLogo.value) || ''"></logo>
            </div>
          </b-col>
          <b-col cols="3">
            <!-- cart icon -->
            <b-nav align="right">
              <b-nav-item :to="`/cart`">
                <p class="h6 m-0">
                  <b-icon-cart-3></b-icon-cart-3>
                  <b-badge v-if="itemCount" pill variant="light">
                    {{ itemCount }}
                  </b-badge>
                </p>
              </b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
        <div class="mb-2 pt-2 d-flex align-items-center">
          <b-nav>
            <notification-dropdown></notification-dropdown>
          </b-nav>
          <!-- search -->
          <search
            class="search flex-grow-1"
            :placeholder="$t('search_placeholder')"
          ></search>
          <!-- user menu  -->
          <top-menu></top-menu>
        </div>
      </div>
    </b-container>
  </header>
</template>

<script>
import { BNav, BNavItem, BIconCart3, BBadge } from "bootstrap-vue";
import Logo from "./Logo";
import Search from "./Search";
import TopMenu from "./TopMenuMobile_Next.vue";
import MobileSideNav from "./MobileSideNav.vue";

export default {
  components: {
    Logo,
    Search,
    TopMenu,
    BNav,
    BNavItem,
    BIconCart3,
    BBadge,
    MobileSideNav,
    NotificationDropdown: () =>
      import("../notification/NotificationDropdown.vue"),
  },
  data: () => ({
    text: "",
  }),
  computed: {
    mainLogo() {
      return this.$store.getters.getSetting("LOGO");
    },
    itemCount() {
      return this.$store.getters.itemCount;
    },
  },
};
</script>
