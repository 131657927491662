import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth.module';
import { recentlyViewedItems } from './recentlyViewedItems.module';
import shopService from '../services/shop.service';
import cartService from '../services/cart.service';

Vue.use(Vuex);

// methods - temp cart
const cs_getCartList = async () => {
  try {
    let res = await cartService.getCartList();
    let tempCart = res.data;
    let cartList = [];
    let cartShop = [];

    tempCart.forEach(tempItem => {
      cartList.push({
        tempId: tempItem.id,
        ...tempItem.product,
        qty: tempItem.qty,
        shop: tempItem.product.register,
        shopId: tempItem.product.registerId,
        remark: ''
      });

      const existedShop = cartShop.find(
        cartShop => cartShop.id === tempItem.product.registerId
      );
      if (!existedShop)
        cartShop.push({
          ...tempItem.product.register,
          remark: ''
        });
    });

    return {
      cartList,
      cartShop
    };
  } catch (error) {
    console.error(error);
  }
};

const cs_addToCart = async (state, item) => {
  let itemList = [];
  const { id, qty, remark } = item;

  if (item.qty)
    itemList.push({
      productId: id,
      qty,
      remark,
      registerId: state.auth.user.registerDTO.id
    });
  else
    itemList.push({
      productId: id,
      qty: 1,
      remark,
      registerId: state.auth.user.registerDTO.id
    });

  try {
    await cartService.registerToCart(itemList);
  } catch (error) {
    console.error(error);
  }
};

const cs_removeFromCart = async tempId => {
  try {
    await cartService.removeFromCart([tempId]);
  } catch (error) {
    console.error(error);
  }
};

const cs_clearCart = async () => {
  try {
    await cartService.checkout();
  } catch (error) {
    console.error(error);
  }
};
// end - methods - temp cart

export default new Vuex.Store({
  state: {
    currentShop: null,
    currentError: null,
    cartList: [],
    cartShop: [],
    deliveryInfo: {
      address: null,
      fee: null
    },
    settings: [],
    ads: []
  },
  getters: {
    currentShop: state => state.currentShop,
    itemCount: state => state.cartList.length,
    cartListByShop: state => {
      return state.cartShop.map(shop => {
        let cartProductList = state.cartList.map(item => {
          let isBaseUnit = true;
          let appliedUnit = { name: 'Basic', id: null };

          if (item.productUnits && item.productUnits.length > 0) {
            // check multi unit
            let pUnitArrLen = item.productUnits.length;
            let pUnitItem = null;

            for (let index = 0; index < pUnitArrLen; index++) {
              pUnitItem = null;

              // check last or between, and get unit
              if (
                (index == pUnitArrLen - 1 &&
                  item.qty >= item.productUnits[index].factor) ||
                (item.qty >= item.productUnits[index].factor &&
                  item.qty < item.productUnits[index + 1].factor)
              )
                pUnitItem = item.productUnits[index];

              // set price
              if (pUnitItem) {
                if (item.isSpecialOffer)
                  item.price = pUnitItem.unitSpecialPrice;
                else if (item.isPromo) item.price = pUnitItem.unitPromoPrice;
                else item.price = pUnitItem.unitPrice;

                isBaseUnit = false; // to announce to use other unit's price
                appliedUnit.id = pUnitItem.id;
                appliedUnit.name = pUnitItem.factorUnitName;
                break;
              }
            }
          }

          if (isBaseUnit) {
            if (item.isPromo && item.isSpecialOffer && item.specialOfferPrice)
              item.price = item.specialOfferPrice;
            else if (item.isSpecialOffer && item.specialOfferPrice)
              item.price = item.specialOfferPrice;
            else if (item.isPromo && item.promoPrice)
              item.price = item.promoPrice;
            else if (state.auth.status.loggedIn) item.price = item.minPrice;
            else item.price = item.maxPrice;
          }

          return {
            ...item,
            amount: item.price * item.qty,
            appliedUnit
          };
        });

        let productList = cartProductList.filter(
          cartItem => cartItem.shopId == shop.id
        );

        let totalAmount = productList.reduce((total, product) => {
          return total + product.amount;
        }, 0);

        let netAmount = totalAmount;
        let deliveryAmount = 0;
        // delivery fee
        if (
          !shop.hideDeliveryFee &&
          !shop.isDeliveryFree &&
          state.deliveryInfo.fee
        ) {
          deliveryAmount = state.deliveryInfo.fee.deliveryFee;
          netAmount = totalAmount + deliveryAmount;
        }

        return {
          ...shop,
          productList,
          totalAmount,
          deliveryAmount,
          netAmount
        };
      });
    },
    getShopSubtotal: (state, getters) => {
      return getters.cartListByShop.reduce((total, shop) => {
        if (!shop.isQuotation) return total + shop.netAmount;
        else return total;
      }, 0);
    },
    getSetting: state => name => {
      let setting = state.settings.filter(setting => setting.name === name);
      if (setting.length > 0) return setting[0];
      else return {};
    },
    getCurrentError: state => state.currentError,
    getAds: state => (position, placement, sitePage) =>
      state.ads.find(
        ads =>
          ads.position === position &&
          ads.placement === placement &&
          ads.sitePage === sitePage
      )
  },
  actions: {
    getCurrentShop({ commit }, shopId) {
      if (/^\d+$/.test(shopId)) {
        shopService.getShopById(shopId).then(
          response => {
            commit('enterShop', response.data);
          },
          error => {
            console.error(error);
            commit('setError', error.response.data);
          }
        );
      } else {
        shopService.getShopBySlug(shopId).then(
          response => {
            commit('enterShop', response.data);
          },
          error => {
            console.error(error);
            commit('setError', error.response.data);
          }
        );
      }
    },
    removeCurrentShop({ commit }) {
      commit('clearShop');
    },
    addToCart({ commit, state }, { product, shop }) {
      const cartItem = state.cartList.find(item => item.id === product.id);
      if (!cartItem) {
        commit('addItemToCart', {
          ...product,
          shop,
          shopId: shop.id,
          remark: ''
        });
        commit('addShopToList', { ...shop, remark: '' });

        // register as temp
        cs_addToCart(state, product);
      } else {
        if (product.qty > 1) commit('increaseByQuantity', product);
        else commit('increaseItemQuantity', product);
      }
    },
    removeFromCart({ commit, state }, { id, tempId }) {
      const index = state.cartList.findIndex(cartItem => cartItem.id === id);
      if (index >= 0) {
        let orderShop = Object.assign({}, state.cartList[index].shop);

        commit('removeItemFromCart', index);

        // remove from temp
        cs_removeFromCart(tempId);

        /**
         * remove လုပ်လိုက်တဲ့ item ရဲ့ shop မှာ item ရှိသေးလားစစ်ပြီး မရှိရင် shop ကိုဖယ်
         */
        if (
          state.cartList.filter(cartItem => cartItem.shopId == orderShop.id)
            .length == 0
        )
          commit('removeShopOrder', orderShop);
      }
    },
    updateCart({ commit, state }, { type, product }) {
      const cartItem = state.cartList.find(
        cartItem => cartItem.id === product.id
      );
      if (cartItem) {
        if (type == 'INCREASE') commit('increaseItemQuantity', product);
        else if (type == 'DECREASE') commit('decreaseItemQuantity', product);
        else commit('updateQty', product);
      }
    },
    clearCart({ commit }) {
      commit('clearCart');
      // clear temp cart
      cs_clearCart();
    },
    updateRemark({ commit }, payload) {
      if (payload.type == 'PRODUCT') commit('updateProductRemark', payload);
      else if (payload.type == 'SHOP_ORDER')
        commit('updateShopOrderRemark', payload);
    },
    updateDeliveryInfo({ commit }, payload) {
      commit('updateDeliveryInfo', payload);
    },
    saveSettings({ commit }, payload) {
      payload.forEach(setting => {
        if (
          (setting.name == 'HOMEPAGEADS' || setting.name == 'CAROUSEL') &&
          setting.value
        ) {
          let image = String(setting.value).split('|');
          // parse obj
          setting.objArr = image.map(strObj => {
            return JSON.parse(strObj);
          });
        }
      });
      commit('setSettings', payload);
    },
    async getCartList({ commit }) {
      try {
        let data = await cs_getCartList();
        commit('setCart', data);
      } catch (error) {
        console.error(error);
      }
    },
    saveAds({ commit }, payload) {
      commit('setAds', payload);
    }
  },
  mutations: {
    enterShop(state, shop) {
      state.currentShop = shop;
    },
    clearShop(state) {
      state.currentShop = null;
    },
    addItemToCart(state, item) {
      if (item.qty) state.cartList.push(item);
      else state.cartList.push({ ...item, qty: 1 });
    },
    addShopToList(state, shop) {
      const existedShop = state.cartShop.find(
        cartShop => cartShop.id === shop.id
      );
      if (!existedShop) state.cartShop.push(shop);
    },
    removeItemFromCart(state, index) {
      state.cartList.splice(index, 1);
    },
    increaseItemQuantity(state, { id }) {
      const cartItem = state.cartList.find(item => item.id === id);
      cartItem.qty++;
    },
    increaseByQuantity(state, { id, qty }) {
      const cartItem = state.cartList.find(item => item.id === id);
      cartItem.qty += qty;
    },
    decreaseItemQuantity(state, { id }) {
      const cartItem = state.cartList.find(item => item.id === id);
      if (cartItem.qty > 1) cartItem.qty--;
    },
    updateQty(state, { id, qty }) {
      const cartItem = state.cartList.find(item => item.id === id);
      const pattern = /^[0-9]+$/;
      if (pattern.test(qty)) cartItem.qty = qty;
      else cartItem.qty = 1;
    },
    clearCart(state) {
      state.cartList = [];
      state.cartShop = [];
    },
    updateProductRemark(state, { id, remark }) {
      const product = state.cartList.find(item => item.id == id);
      product.remark = remark;
    },
    updateShopOrderRemark(state, { id, remark }) {
      const shopOrder = state.cartShop.find(item => item.id == id);
      shopOrder.remark = remark;
    },
    removeShopOrder(state, shop) {
      const index = state.cartShop.findIndex(item => item.id == shop.id);
      if (index >= 0) state.cartShop.splice(index, 1);
    },
    updateDeliveryInfo(state, info) {
      state.deliveryInfo = info;
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    setCart(state, { cartList, cartShop }) {
      state.cartList = cartList;
      state.cartShop = cartShop;
    },
    setError(state, payload) {
      state.currentError = payload;
    },
    setAds(state, payload) {
      state.ads = payload;
    }
  },
  modules: {
    auth,
    recentlyViewedItems
  }
});
