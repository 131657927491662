<template>
  <div>
    <b-card
      v-if="show"
      no-body
      id="home-category-card"
      class="category-card border-0 shadow-sm pt-3 pb-3 flex-grow-1 h-100"
    >
      <div class="custom-card-title text-left px-3 pb-1 font-weight-bold">
        <strong>{{ $t("categoryCard_title") }}</strong>
      </div>

      <b-list-group>
        <b-list-group-item
          v-for="(shopCategory, index) in showCategoryList"
          :key="`sc-${shopCategory.id}`"
          class="d-flex align-items-center border-0 px-3 py-2"
          :id="`home-category-card-item-${index}`"
          :to="{ path: '/shops?name=&shopCategoryId=' + shopCategory.id }"
        >
          <b-img
            :src="shopCategory.imgUrl || placeholder"
            class="rounded-circle"
            width="21"
            height="21"
          >
          </b-img>

          <div class="ml-2 text-left text-truncate flex-grow-1">
            {{ shopCategory.name }}
          </div>
        </b-list-group-item>

        <div
          class="flyout-megamenu"
          v-if="moreCategoryList && moreCategoryList.length > 0"
        >
          <b-list-group-item
            class="d-flex align-items-center border-0 px-3 py-2"
            href="#"
          >
            <b-img
              :src="categoryImg"
              class="rounded-circle"
              width="21"
              height="21"
            >
            </b-img>

            <div class="ml-2 text-truncate flex-grow-1">
              <div class="d-flex align-items-center justify-content-between">
                <div>More Categories</div>
                <div>
                  <b-icon-chevron-right></b-icon-chevron-right>
                </div>
              </div>
            </div>
          </b-list-group-item>

          <div class="megamenu-content">
            <div class="px-3 pb-1 text-truncate">More Categories</div>
            <b-list-group>
              <b-list-group-item
                v-for="moreCategory in moreCategoryList"
                :key="`sc-${moreCategory.id}`"
                class="d-flex align-items-center border-0 px-3 py-2"
                :to="{ path: '/shops?name=&shopCategoryId=' + moreCategory.id }"
              >
                <b-img
                  :src="moreCategory.imgUrl || placeholder"
                  class="rounded-circle"
                  width="21"
                  height="21"
                >
                </b-img>

                <div class="ml-2 text-center text-truncate">
                  {{ moreCategory.name }}
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </b-list-group>
    </b-card>
    <b-card 
    v-else
    class="category-card border-0 shadow-sm pt-3 pb-3 flex-grow-1 h-100"
    >
      <div>
        <b-skeleton width="60%" height="10%"></b-skeleton>
      </div>
      <b-list-group v-for="item in (width>1075?7:5)" :key="item" >
        <div class="d-flex align-items-start justify-content-start border-0 px-3 py-2">
          <b-skeleton
            width="10%"
            class="mr-1 rounded-circle"
          ></b-skeleton>
          <b-skeleton width="70%" height="20"></b-skeleton>
        </div>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BListGroup,
  BListGroupItem,
  BIconChevronRight,
  BSkeleton,
} from "bootstrap-vue";
import shopService from "../../services/shop.service";

export default {
  components: {
    BCard,
    BImg,
    BListGroup,
    BListGroupItem,
    BIconChevronRight,
    BSkeleton,
  },
  data: () => ({
    placeholder: require("../../assets/images/yoma-placeholder.png"),
    categoryImg: require("../../assets/images/category.png"),
    shopCategoryList: [],
    showCategoryList: [],
    moreCategoryList: [],
    show:false,
    width:""
  }),

  created() {
    Promise.all([this.getShopCategory()]).then(() => {
      this.renderCategory();
    });
  },
  watch:{
    "this.width":{
      handler(n){
        this.width = n;
      console.log(n);
      },
      deep: true
    }
  },
  mounted() {
    this.width = window.innerWidth;
    window.addEventListener("resize", this.renderCategory);
  },
  unmounted() {
    window.removeEventListener("resize", this.renderCategory);
  },
  methods: {
    renderCategory() {
      let showCount = this.getShowableCount();
      if (this.shopCategoryList && this.shopCategoryList.length > showCount) {
        this.showCategoryList = this.shopCategoryList.slice(0, showCount);
        this.moreCategoryList = this.shopCategoryList.slice(showCount);
      } else {
        this.showCategoryList = this.shopCategoryList;
        this.moreCategoryList = [];
      }
    },
    getShowableCount() {
      let carouselEl = document.getElementById("home-carousel-banner");
      let categoryEl = document.getElementById("home-category-card");

      if (!carouselEl || !categoryEl) return 0;

      let cardHeight = carouselEl.offsetHeight;

      // set category card height
      categoryEl.style.maxHeight = cardHeight + +"px";

      let preservedHeight =
        32 /*card padding*/ -
        8 /* adujst */ +
        31 /*card title*/ +
        40; /*more item*/

      return parseInt((cardHeight - preservedHeight) / 40 /*single item*/);
    },
    async getShopCategory() {
      try {
        let res = await shopService.getShopCategoy("ACTIVE");
        let _shc = res.data;
        this.show = true;
        this.shopCategoryList = _shc.map((sc) => {
          return {
            ...sc,
            imgUrl: sc.imageUrl ? sc.imageUrl.replace("\\", "/") : "",
          };
        });
      } catch (error) {
        console.error(error);
        this.$nprogress.done();
      }
    },
    setCategory(category) {
      alert(category.name);
      this.$emit("set-category", category);
    },
  },
};
</script>
