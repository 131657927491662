<template>
  <div>
    <site-top-bar class="d-none d-md-block"></site-top-bar>

    <!-- above header ads -->

    <div v-if="aboveHeaderAds && $route.name=='Home'" style="max-width: 1500px; margin: auto">
      <div v-if="aboveHeaderAds.redirectLink">
        <a :href="aboveHeaderAds.redirectLink" target="_blank"><b-img :src="aboveHeaderAds.imageUrl" class="header-ads"></b-img></a>
      </div>
      <b-img v-else :src="aboveHeaderAds.imageUrl" class="header-ads"></b-img>
    </div>

    <top-header class="d-none d-md-block"></top-header>
    <top-header-mobile class="d-block d-md-none"></top-header-mobile>

    <!-- below header ads -->
    <div v-if="belowHeaderAds && $route.name=='Home'" style="max-width: 1500px; margin: auto">
      <div v-if="belowHeaderAds.redirectLink">
        <a :href="belowHeaderAds.redirectLink" target="_blank"><b-img :src="belowHeaderAds.imageUrl" class="header-ads"></b-img></a>
      </div>
            <b-img v-else :src="belowHeaderAds.imageUrl" class="header-ads"></b-img>
    </div>

    <router-view  />
    <!-- above footer ads -->
    
    <div v-if="aboveFooterAds" style="max-width: 1500px; margin: auto">
      <div v-if="aboveFooterAds.redirectLink">
        <a :href="aboveFooterAds.redirectLink" target="_blank"><b-img :src="aboveFooterAds.imageUrl" class="header-ads"></b-img></a>
      </div>
      <b-img v-else :src="aboveFooterAds.imageUrl" class="header-ads"></b-img>
    </div>
    <base-footer></base-footer>
  </div>
</template>

<script>
import TopHeader from "../components/header/TopHeader_Next.vue";
import TopHeaderMobile from "../components/header/TopHeaderMobile_Next.vue";
import SiteTopBar from "../components/header/SiteTopBar.vue";

import { BImg } from "bootstrap-vue";

export default {
  components: {
    TopHeader,
    TopHeaderMobile,
    BaseFooter: () =>
      import(
        /* webpackChunkName: "base_footer" */ "../components/footer/Footer"
      ),
    SiteTopBar,
    BImg,
  },
  computed: {
    aboveHeaderAds() {
      return this.$store.getters.getAds("ABOVE", "HEADER", "HOME");
    },
    belowHeaderAds() {
      return this.$store.getters.getAds("BELOW", "HEADER", "HOME");
    },
    aboveFooterAds() {
      return this.$store.getters.getAds("ABOVE", "FOOTER", "HOME");
    },
  },
};
</script>

<style scoped>
.header-ads {
  width: 100%;
}
</style>
