import axios from "../helpers/axios-auth"

class settingService {

  getSystemSetting() {
    return axios.get("/system-settings");
  }

}
export default new settingService();
